import { Site, slugify } from "@ihr-radioedit/inferno-core";
import { AppEnvironment } from "../abstracts/store/base-store.abstract";

export function getLiveStationUrl(site: Site, env: AppEnvironment) {
  const slug = site.sections.general?.name ? slugify(site.sections.general.name) : "";
  const id = site.getStreamId();
  const sc = "inferno";
  const pname = site.index.slug.toUpperCase();

  if (id) {
    return `${env.NATIONAL_SITE_URL}/live/${slug}-${id}/?sc=${sc}&pname=${pname}`;
  }
  return null;
}
