import { inject, observer } from "mobx-react";
import { RefObject, useCallback, useRef } from "react";
import * as React from "react";
import { Link, LinkProps } from "react-router-dom";
import { HashLink } from "react-router-hash-link";
import {
  AnalyticsEventAction,
  ClickSection,
  getRedirect,
  redirectDestinationUrl,
  Store,
} from "@inferno/renderer-shared-core";
import { SitesDestinationType } from "@ihr-radioedit/inferno-webapi";
import { ILog } from "@ihr-radioedit/inferno-core";
import { isWindowDefined } from "@inferno/renderer-shared-core";

const log = ILog.logger("MagicLink");

export interface MagicLinkProps extends LinkProps {
  store?: Store;
  external?: boolean;
  label?: string;
  tabbable?: boolean;
  context?: string | null;
  sectionName?: string;
  action?: AnalyticsEventAction;
  ref?: (instance: HTMLAnchorElement | null) => void | RefObject<HTMLAnchorElement> | null;
}

export const MagicLink = inject("store")(
  observer((props: MagicLinkProps & React.ClassAttributes<Link>) => {
    const {
      store,
      external: isExternal,
      label = "",
      tabbable = true,
      sectionName,
      to,
      action,
      children,
      ...linkProps
    } = props;

    if (!store) {
      return null;
    }

    const context = useRef(props.context);
    const linkTo = typeof to === "string" ? to.trim() : to;

    const trackClick = useCallback(
      (trackSectionName: string, trackHref: string | any) => {
        const page = store.page.currentPage;
        const pageName = page ? `${store.microsite ? "microsite_" : ""}${page.name}` : "";

        if (!context.current) {
          if (label) {
            context.current = label;
          } else if (typeof children === "string") {
            context.current = children.toString();
          } else if (typeof trackHref === "string") {
            context.current = trackHref;
          } else {
            context.current = "";
          }
        }

        if (sectionName) {
          trackSectionName = sectionName;
        }

        let referrer = "";
        if (isWindowDefined()) {
          referrer = window.location.href;
        }

        store.onAnalyticsAction.dispatch({
          sectionName: trackSectionName,
          pageName,
          context: context.current,
          action: action || "click",
          url: trackHref,
          referrer,
        });
      },
      [store, sectionName, context, action, label, children],
    );

    const { site, env } = store;
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { innerRef, replace, ...anchorProps } = linkProps;
    let href = linkTo;

    // Turn fully qualified urls to the same site into relative urls
    if (site && env) {
      const primaryDomain = site.getPrimaryDomain();
      if (primaryDomain && typeof linkTo === "string" && linkTo.includes(primaryDomain)) {
        const destination = linkTo.startsWith("//") ? `${env.PROTOCOL}:${linkTo}` : linkTo;

        try {
          const link = new URL(destination);
          href = link.toString().replace(link.origin, "");
        } catch (e) {
          log.error("Could not parse url", e);
        }
      }
    }
    let external = isExternal !== undefined;

    // Treat protocol-agnostic urls and a hash as external
    if ((typeof linkTo === "string" && linkTo.startsWith("//")) || linkTo === "#") {
      external = true;
    }

    // Override destination if there is a redirect at play
    if (store && href) {
      const result = getRedirect(site, env, href.toString());
      if (result) {
        const { redirect, match } = result;
        if (redirect) {
          // Enforce url redirects
          if (redirect.destination.type === SitesDestinationType.Url && redirect.destination.value) {
            href = redirectDestinationUrl(redirect, href.toString(), match) || href;
          } else if (redirect.destination.type === SitesDestinationType.Brand) {
            external = true;
          }
        }
      }
    }

    // Treat fully qualified urls as external
    if (typeof href === "string" && href.startsWith("http")) {
      external = true;
    }

    // Add trailing slashes to any relative urls that don't have them
    if (!external && href) {
      try {
        const resolvedLink = new URL(`https://example.com${href}`);
        if (!resolvedLink.pathname.endsWith("/")) {
          resolvedLink.pathname += "/";
          href = resolvedLink.toString().replace(resolvedLink.origin, "");
        }
      } catch (ignore) {
        // pass
      }
    }

    const ariaProps: { "aria-label"?: string } = {};

    if (label) {
      ariaProps["aria-label"] = label;
    }

    // External links need to be anchor tags
    if (href && external) {
      // external links should always open in new window
      // but we're treating microsites as internal so they should not open in new window
      if (!anchorProps.target) {
        anchorProps.target = "_blank";
      }

      if (anchorProps.target === "_blank") {
        anchorProps.rel = "noopener";
      }

      if (anchorProps.context) {
        delete anchorProps.context;
      }

      return (
        <ClickSection.Consumer>
          {sectionVal => (
            <a
              href={href as string}
              {...(anchorProps as React.AnchorHTMLAttributes<HTMLAnchorElement>)}
              {...ariaProps}
              onClick={() => trackClick(sectionVal, href)}
            >
              {children}
            </a>
          )}
        </ClickSection.Consumer>
      );
    }

    // React Router Link's trigger postbacks if target is set
    linkProps.target = undefined;

    if (linkProps.context) {
      delete linkProps.context;
    }
    return (
      <ClickSection.Consumer>
        {sectionVal => (
          <HashLink
            to={href}
            {...linkProps}
            {...ariaProps}
            tabIndex={tabbable ? 0 : -1}
            onClick={() => trackClick(sectionVal, href)}
          >
            {children}
          </HashLink>
        )}
      </ClickSection.Consumer>
    );
  }),
);

export default MagicLink;
