import { ILog, Site, getSlugByBrandTag } from "@ihr-radioedit/inferno-core";
import {
  SitesDestinationType,
  SitesRedirect,
  SitesRedirectQueryParamsHandling,
  SitesSearchOverride,
  Sites_Lookup_By,
} from "@ihr-radioedit/inferno-webapi";
import { AppEnvironment } from "../abstracts/store/base-store.abstract";
import { getLiveStationUrl } from "../utilities/live-station";

const log = ILog.logger("redirects");

interface RedirectDestinationMap {
  [destinationType: string]: (
    redirect: SitesRedirect | SitesSearchOverride,
    path: string,
    source: RegExp | string,
  ) => Promise<string | null>;
}

export function isRedirect(redirect: SitesRedirect | SitesSearchOverride): redirect is SitesRedirect {
  return redirect.hasOwnProperty("regex");
}

//  source: ?a=1&b=2
//  destination: ?a=5&c=8
//  DISCARD => ?a=5&c=8
//  ORIGINAL => ?a=1&b=2
//  MERGE => ?a=5&b=2&c=8
// Chart!
// https://lucid.app/lucidchart/1b1c9ea0-a70e-43b4-bef9-032950373f86/edit?referringApp=slack&shared=true&page=0_0&invitationId=inv_e8458518-793e-4c43-a75b-cb53ee8f473d#
export const redirectDestinationUrl = (
  redirect: SitesRedirect | SitesSearchOverride,
  path: string,
  source: RegExp | string,
) => {
  if (isRedirect(redirect)) {
    const [, sourceQS] = path.split("?");
    let redirectDest = redirect.regex
      ? path.replace(source, redirect.destination.value.replace(/\\/g, "$"))
      : redirect.destination.value;

    if (redirect.queryParamsHandling === SitesRedirectQueryParamsHandling.Discard) {
      // Keep redirect destination as is
    } else if (redirect.queryParamsHandling === SitesRedirectQueryParamsHandling.Original) {
      // Throw out redirect's query params
      redirectDest = redirectDest.split("?")[0] + (sourceQS ? `?${sourceQS}` : "");
    } else if (redirect.queryParamsHandling === SitesRedirectQueryParamsHandling.Merge) {
      // Combine query params
      const parsedDestinationQS = new URLSearchParams(redirectDest.split("?")[1]);
      const parsedSourceQS = new URLSearchParams(path.split("?")[1]);
      const qps = {};
      // Source query params first so destination overrides them
      // @ts-ignore - TODO: Fix this
      Array.from(parsedSourceQS.entries()).forEach(([k, v]) => (qps[k] = v));
      // @ts-ignore - TODO: Fix this
      Array.from(parsedDestinationQS.entries()).forEach(([k, v]) => (qps[k] = v));
      const merged = Object.entries(qps)
        .map(([k, v]) => `${k}=${v}`)
        .join("&");
      if (merged) {
        redirectDest = `${redirectDest.split("?")[0]}?${merged}`;
      }
    }
    return redirectDest || null;
  }
  return redirect.destination.value || null;
};

export const redirectDestination: RedirectDestinationMap = {
  [SitesDestinationType.Url]: async (redirect, path, source) => redirectDestinationUrl(redirect, path, source),
  [SitesDestinationType.Brand]: async redirect => {
    // Note: Brands do not have any query-param redirect handling
    if (!redirect.destination.value) {
      return null;
    }
    const { value: brand } = redirect.destination;
    const micrositeSlug = await getSlugByBrandTag({
      type: Sites_Lookup_By.Brand,
      value: brand,
    });
    return micrositeSlug ? `/featured/${micrositeSlug}/` : null;
  },
};

export function normalizePath(path: string) {
  const [url, params = ""] = path.split("?");
  if (params) {
    return url.endsWith("/") ? [url.toLowerCase(), params].join("?") : [`${url}/`.toLowerCase(), params].join("?");
  } else {
    return url.endsWith("/") ? url.toLowerCase() : `${url}/`.toLowerCase();
  }
}

export function pathMatches(left: string, right: string) {
  const normalizedPath = normalizePath(right);
  return normalizePath(left) === normalizedPath ? normalizedPath : undefined;
}

export function regexPathMatches(source: string, current: string) {
  const re = new RegExp(source, "i");
  return normalizePath(current).match(re) ? re : undefined;
}

interface RedirectMatch {
  redirect: SitesRedirect | SitesSearchOverride;
  match: RegExp | string;
}

export const getRedirect = (site: Site, env: AppEnvironment, path: string): RedirectMatch | undefined => {
  const { config } = site;
  const liveUrl = getLiveStationUrl(site, env);
  const listenLiveRedirect: SitesRedirect | null = liveUrl
    ? {
        source: "/listen/",
        destination: {
          value: liveUrl,
          type: SitesDestinationType.Url,
        },
        permanent: true,
        id: "",
        disabled: false,
        regex: false,
        priority: 0,
        queryParamsHandling: SitesRedirectQueryParamsHandling.Original,
        matchQueryString: false,
      }
    : null;
  return (
    findRedirect(path, sortRedirects([...config.redirects, listenLiveRedirect])) ||
    findSearchOverride(path, config.searchOverrides)
  );
};

const sortRedirects = (redirects: Array<SitesRedirect | null>): SitesRedirect[] => {
  // Sort redirects so exact matches have precedence over regex and regex matches are sorted by priority
  const nonRegexRedirects: SitesRedirect[] = [];
  const regexRedirects: SitesRedirect[] = [];

  redirects.map(redirect => {
    if (!redirect) {
      return;
    }

    if (redirect.regex) {
      regexRedirects.push(redirect);
    } else {
      nonRegexRedirects.push(redirect);
    }
  });

  return [...nonRegexRedirects, ...regexRedirects.sort((a, b) => a.priority - b.priority)];
};

export const findRedirect = (path: string, redirects: SitesRedirect[]): RedirectMatch | undefined => {
  const now = Date.now();
  for (const redirect of redirects) {
    if (
      !redirect.source ||
      redirect.disabled ||
      (redirect.schedule && (redirect.schedule.begin > now || redirect.schedule.end < now))
    ) {
      continue;
    }

    const matchPath = redirect.matchQueryString ? path : path.split("?")[0];

    const match = redirect.regex
      ? regexPathMatches(redirect.source, matchPath)
      : pathMatches(redirect.source, matchPath);
    if (match) {
      return { redirect, match };
    }
  }
};

export const findSearchOverride = (path: string, overrides: SitesSearchOverride[]): RedirectMatch | undefined => {
  const now = Date.now();
  for (const override of overrides) {
    if (!override.disabled && (!override.schedule || (override.schedule.begin <= now && override.schedule.end > now))) {
      for (const keyword of override.keywords) {
        const match = pathMatches(`/search/${encodeURIComponent(keyword.toLowerCase().trim())}`, path);
        if (match) {
          log.debug("Search override match found:", override);
          return {
            redirect: override,
            match,
          };
        }
      }
    }
  }
};
